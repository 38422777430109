<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Centres</h4>
					</template>
					<template v-slot:headerAction>
						<div class="float-right">
							<b-button v-b-modal.centre-modal variant="primary" class="btn-sm">Add Centre</b-button>
						</div>
					</template>
					<template v-slot:body>
						<!----- Listing length and search starts ----->
						<div class="row listing-length-row">
							<div class="col-sm-12 col-md-6">
								<div class="listing-length" >
									<label
										>Show
										<select class="form-control" v-model="listing_length" @change="fetchCentre()">
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="15">15</option>
										</select>
										entries
									</label>
								</div>								
							</div>
							<div class="col-sm-12 col-md-6">
								<div class="div-search">
									<label>
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchCentre()" />
									</label>
								</div>							
								
							</div>
						</div>
						<!----- Listing length and search ends ----->

						<!--- Listing starts ----->
						<div class="table-responsive">
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>
									<tr>
										<th width="10%">Centre Name</th>			
										<th width="10%">Centre Code</th>
										<th width="15%">Created on</th>
										<th width="10%">Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(centres.length)">
									<tr v-for="centre in centres" :key="centre.id">
										<td>{{ centre.centre }}</td>
										<td>{{ centre.centre_code }}</td>
										<td>{{ centre.created_at | formatDateTime }}</td>
										<td>
											<b-button variant="primary" class="btn btn-sm mt-2 mr-1" @click="editCentre(centre)">Edit</b-button>
											<b-button variant="danger" class="btn mt-2 mr-1 btn-sm" @click="deactivateCentre(centre)" v-if="centre.status == 1">Unpublish</b-button>
											<b-button variant="success" class="btn mt-2 mr-1 btn-sm" @click="activateCentre(centre)" v-else>Activate</b-button>
											<router-link :to="{ name: 'centres.students', params: { id: centre.id }}" class="btn btn-primary mt-2 mr-1 btn-sm">Students</router-link>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="4" class="text-center">No data</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- Listing ends -->
						<!---- modal starts ---->
						<b-modal ref="centre-modal" id="centre-modal" :title="modal_title" hide-footer @hidden="clearForm()">
							<b-form @submit.prevent="addCentre">
								
								<b-col md="12" class="form-group">
									<label class="control-label">Centre Name<span class="required">*</span></label>
									<b-form-input v-model="centre.centre_name" v-bind:class="{ 'is-invalid': Boolean(errors.centre_name) }"></b-form-input>
									<div v-for="(val, index) in errors.centre_name" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>

								<b-col md="12" class="form-group">
									<label class="control-label">Centre Code</label>
									<b-form-input v-model="centre.centre_code" v-bind:class="{ 'is-invalid': Boolean(errors.centre_code) }"></b-form-input>
									<div v-for="(val, index) in errors.centre_code" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>
								
								
								<b-col md="12">
									<b-button type="submit" class="mr-3" variant="primary">{{ button_title }}</b-button>
									<b-button class="" variant="light" @click="hideModal('centre-modal')">Close</b-button>
								</b-col>
							</b-form>
						</b-modal>
						<!---- modal ends ----->
						<!----pagination starts --->
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
									<a class="page-link" href="#" @click.prevent="fetchCentre(page.url + '&limit=' + listing_length + '&keyword=' + keyword)" tabindex="-1">
										<span v-if="index == 0">Prev</span>
										<span v-else-if="index == pagination.length - 1">Next</span>
										<span v-else>{{ page.label }}</span>
									</a>
								</li>
							</ul>
						</nav>
						<!----pagination ends --->
					</template>
				</card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
export default {
	name: "Centres",
	data() {
		return {
			centres: [],
			centre: {
				id: null,
			   	centre_name: "",
			   	centre_code: "",
			},
			
			modal_title: "Add Centre",
			button_title: "Save",
			pagination: {},
			edit: false,
			centre_status: {
				id: "",
				status: "",
			},
			keyword: "",
			showsearch: false,
			listing_length: 10,
			status: '',
			token: "",
			errors: {},
			activate: false,
         	deactivate: false,

         	active_page_url: null,
		};
	},
	mounted() {
			
	},

	created() {
		this.fetchCentre();
	},

		methods: {
		    getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},

            fetchCentre(page_url = null) 
			{
				let url = "/api/centres/index?limit=" + this.listing_length + "&keyword=" + this.keyword;
				page_url = page_url || url;
				this.active_page_url = page_url;   
				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						this.centres = responseData.data;
						this.pagination = responseData.links;
					})
					.catch((err) => console.log(err));
			},

			showModal(modal) {
				this.$refs[modal].show();
			},

			hideModal(modal) {
				this.$refs[modal].hide();
			},
            
            addCentre() {
				this.getToken();
				let formData = new FormData();
				formData.append("centre_name", this.centre.centre_name);
				formData.append("centre_code", this.centre.centre_code);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/centres/add";
					this.active_page_url = null;
				} else {
					url = "/api/centres/update";
					formData.append("centre_id", this.centre.id);
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.clearForm();
						this.hideModal("centre-modal");
						this.fetchCentre(this.active_page_url);
					})
					.catch((error) => {
						console.log(error.response.data);
						if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			}, 

			editCentre(centre) {	
			    this.edit = true;
				this.centre.id = centre.id;
				this.centre.centre_name = centre.centre;
				this.centre.centre_code = centre.centre_code;
				this.errors = {};			
				this.modal_title = "Edit Centre";
				this.button_title = "Update";
				this.showModal("centre-modal");
			},
			clearForm() {								
				this.modal_title = "Add Centre";
				this.button_title = "Add";
				this.edit = false;
				this.centre.centre_name = "";
			   	this.centre.centre_code = "";
			},
			
			activateCentre(centre) {
				this.activate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to activate this centre?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		        .then(value => {
		            this.activate = value
		            if(this.activate){
						this.centre_status.id = centre.id;
						this.centre_status.status = "1";
						this.updateCentreStatus();
					}
		        })
		         .catch(err => {
		            console.log(err);
		        })
			},
			deactivateCentre(centre) {
				this.deactivate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to deactivate this centre?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		         .then(value => {
		            this.deactivate = value
		            if(this.deactivate){
						this.centre_status.id = centre.id;
						this.centre_status.status = "0";
						this.updateCentreStatus();
					}
         		})
         		.catch(err => {
           			console.log(err);
         		})
			},
			updateCentreStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/centres/status?centre_id=" + this.centre_status.id + "&status=" + this.centre_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.fetchCentre(this.active_page_url);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},
		},	
};
</script>
